import './App.css';
import React, {Suspense, useEffect} from "react";
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import {useAuth0} from "./react-auth0-spa";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import UpdateIcon from "@material-ui/icons/Update";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Rooms = React.lazy(() => import('./screens/Rooms'));
const SalesPersons = React.lazy(() => import('./screens/Sales Persons'));
const Pending = React.lazy(() => import('./screens/Pending'));
const Upcoming = React.lazy(() => import('./screens/Upcoming'));

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {hasError: false};
		this.state = {error: ''};
		this.state = {openErrorDialog: true};
	}

	componentDidCatch(error, info) {
		console.log("IN COMPONENT DID CATCH")
		// Display fallback UI
		this.setState({hasError: true});
		this.setState({error: error});
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return(
				// <h1>TEST</h1>
				<Dialog
					open={this.state.openErrorDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
					<DialogContent>
						<DialogContentText  style={{whiteSpace: "pre-wrap"}} id="alert-dialog-description">{"An error has occurred: \n" +  this.state.error + "\nPlease contact the system administrators if this keeps happening."}</DialogContentText>
					</DialogContent>
				</Dialog>
			)
		}
		return this.props.children;
	}
}


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		overflow: "hidden",
		maxWidth: "100vw"
	},
}));

function usePersistedState(key, defaultValue) {
	const [state, setState] = React.useState(
		() => JSON.parse(localStorage.getItem(key)) || defaultValue
	);
	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(state));
	}, [key, state]);
	return [state, setState];
}

function Admin() {
	const [component, setComponent] = usePersistedState('component', 'Rooms')
	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const {user, logout} = useAuth0();
	console.log(user)
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const [error, setError] = React.useState('');
	const [openErrorDialog, setErrorDialogOpen] = React.useState(true);

	const drawer = (
		<div>
			<div className={classes.toolbar}/>
			<Divider/>
			<List>
				<ListItem button selected={component === 'Rooms'} onClick={() => setComponent('Rooms')}
				          key={'Users'}>
					<ListItemIcon>
						<PersonIcon/>
					</ListItemIcon>
					<ListItemText primary={'Rooms'}/>
				</ListItem>

				{
				<ListItem button selected={component === 'SalesPersons'}
				          onClick={() => setComponent('Sales Persons')} key={'SalesPersons'}>
					<ListItemIcon>
						<SupervisorAccountIcon/>
					</ListItemIcon>
					<ListItemText primary={'Sales Persons'}/>
				</ListItem>
				}



				{<Divider/>}
				<ListItem button selected={component === 'Pending'} onClick={() => setComponent('Pending')}
				          key={'Pending'}>
					<ListItemIcon>
						<HourglassEmptyIcon/>
					</ListItemIcon>
					<ListItemText primary={'Pending'}/>
				</ListItem>

				<ListItem button selected={component === 'Upcoming'} onClick={() => setComponent('Upcoming')}
				          key={'Upcoming'}>
					<ListItemIcon>
						<UpdateIcon/>
					</ListItemIcon>
					<ListItemText primary={'Upcoming'}/>
				</ListItem>

				<Divider/>
				<ListItem button selected={component === 'Log Out'} onClick={() => {
					logout({returnTo: window.location.origin})
				}} key={'Log Out'}>
					<ListItemIcon>
						<ExitToAppIcon/>
					</ListItemIcon>
					<ListItemText primary={'Log Out'}/>
				</ListItem>

			</List>
		</div>
	);

	return (
		<ErrorBoundary>
		<div className={classes.root}>
			<CssBaseline/>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						className={classes.menuButton}
					>
						<MenuIcon/>
					</IconButton>
					<Typography variant="h6" noWrap>
						{component}
					</Typography>
				</Toolbar>
			</AppBar>
			<nav className={classes.drawer} aria-label="mailbox folders">
				<Hidden smUp implementation="css">
					<Drawer
						variant="temporary"
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<Drawer
						classes={{
							paper: classes.drawerPaper,
						}}
						variant="permanent"
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
			<main className={classes.content}>
				<div className={classes.toolbar}/>

				{component === 'Rooms' &&
				<Suspense fallback={<></>}>
					<Rooms/>
				</Suspense>
				}

				{component === 'Sales Persons' &&
				<Suspense fallback={<></>}>
					<SalesPersons/>
				</Suspense>
				}

				{component === 'Pending' &&
				<Suspense fallback={<></>}>
					<Pending/>
				</Suspense>}

				{component === 'Upcoming' &&
				<Suspense fallback={<></>}>
					<Upcoming/>
				</Suspense>}

				{component === 'Log Out' &&
				alert("Log out")
				}
			</main>
			{error !== '' &&
			<Dialog
				open={openErrorDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{error}</DialogContentText>
				</DialogContent>
			</Dialog>
			}
		</div>
		</ErrorBoundary>
	);
}

export default Admin;
